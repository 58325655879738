import { render, staticRenderFns } from "./groupStatistics.vue?vue&type=template&id=f2c16694&scoped=true&"
import script from "./groupStatistics.vue?vue&type=script&lang=js&"
export * from "./groupStatistics.vue?vue&type=script&lang=js&"
import style0 from "./groupStatistics.vue?vue&type=style&index=0&id=f2c16694&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c16694",
  null
  
)

export default component.exports